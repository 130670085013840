import React, { useRef, useEffect } from 'react';
import './TypedText.css'; // Ensure the correct path to your TypedText.css

function TypedText({ phrases, typingSpeed = 100, neonColors = ['#00ffea', '#7c4dff', '#18ffff'], fontSize = 16 }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const lines = container.querySelectorAll('.typed-line');
    let currentTexts = phrases.map(() => '');
    let charIndex = 0;
    let lineIndex = 0;
    let typingTimeout;

    const typeText = () => {
      if (lineIndex < phrases.length) {
        const currentPhrase = phrases[lineIndex];
        if (charIndex < currentPhrase.length) {
          currentTexts[lineIndex] = currentPhrase.slice(0, charIndex + 1);
          lines[lineIndex].textContent = currentTexts[lineIndex];
          charIndex++;
          typingTimeout = setTimeout(typeText, typingSpeed);
        } else {
          charIndex = 0;
          lineIndex++;
          setTimeout(typeText, typingSpeed);
        }
      } 
    };

    typeText();

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [phrases, typingSpeed, neonColors]);

  const drawNeonLines = (lines) => {
    lines.forEach((line, i) => {
      line.style.textShadow = `
        0 0 2px ${neonColors[i % neonColors.length]},
        0 0 4px ${neonColors[i % neonColors.length]},
        0 0 6px ${neonColors[i % neonColors.length]}
      `;
    });
  };

  return (
    <div ref={containerRef} className="typed-text-container">
      {phrases.map((phrase, index) => (
        <div key={index} className="typed-line-container">
          <p className="typed-line" style={{ fontSize: `${fontSize}px` }}>{phrase}</p>
        </div>
      ))}
    </div>
  );
}

export default TypedText;
