import { levels } from './levels';
import './spaceInvaders.css';
import playerImageSrc from '../assets/spaceship.png';
import alienImageSrc from '../assets/orbitalOctopus.png';
import bombImageSrc from '../assets/bomb.png';
import winImageSrc from '../assets/battleship.png';
import finalImageSrc from '../assets/glman123_16_bit_Pixel_art_coins_falling_out_from_clouds_in_the__d6c2db86-b980-4b69-8d3b-8af26f8720a6.png';

export function startSpaceInvaders(canvas, onGameUpdate, levelIndex = 0) {
  const ctx = canvas.getContext('2d');
  const level = levels[levelIndex];

  const rows = level.rows;
  const cols = level.cols;
  const alienSize = level.alienSize;
  const playerSize = level.playerSize;
  const bulletSize = level.bulletSize;
  const playerSpeed = level.playerSpeed;
  const bulletSpeed = level.bulletSpeed;
  const bombSpeed = level.bombSpeed;
  const bombFrequency = level.bombFrequency;
  const alienMovementPattern = level.alienMovementPattern;

  const xOffset = (canvas.width - (cols * (alienSize + 10))) / 2;
  const yOffset = 50;

  let aliens = [];
  let bullets = [];
  let bombs = [];
  let player = { x: canvas.width / 2 - playerSize / 2, y: canvas.height - playerSize - 8, width: playerSize, height: playerSize, dx: 0 }; // Raised 20px from the bottom
  let isRunning = true;
  let alienDirection = 1;
  let alienStep = 0;
  let centerX = canvas.width / 2;
  let verticalDirection = 1;
  const alienSpeed = 1;

  const playerImage = new Image();
  playerImage.src = playerImageSrc;

  const alienImage = new Image();
  alienImage.src = alienImageSrc;

  const bombImage = new Image();
  bombImage.src = bombImageSrc;

  const winImage = new Image();
  winImage.src = winImageSrc;

  const finalImage = new Image();
  finalImage.src = finalImageSrc;

  function createAliens() {
    aliens = []; // Clear existing aliens
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const alien = {
          x: col * (alienSize + 10) + xOffset,
          y: row * (alienSize + 10) + yOffset,
          width: alienSize + 8,
          height: alienSize,
          row: row,
          col: col
        };
        aliens.push(alien);
      }
    }
  }

  function draw() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!isRunning) {
      return;
    }

    ctx.drawImage(playerImage, player.x, player.y, player.width * 1.5, player.height * 1.5);

    aliens.forEach(alien => {
      ctx.drawImage(alienImage, alien.x, alien.y, alien.width * 2, alien.height * 2);
    });

    ctx.fillStyle = 'yellow';
    bullets.forEach(bullet => {
      ctx.fillRect(bullet.x, bullet.y, bullet.width, bullet.height);
    });

    bombs.forEach(bomb => {
      ctx.drawImage(bombImage, bomb.x, bomb.y, bomb.width * 5, bomb.height * 5);
    });

    ctx.fillStyle = '#00ff00';
    ctx.font = '15px "Press Start 2P"';
    ctx.fillText(`Level ${level.levelNumber}`, 650, 30);

    onGameUpdate('update', { player, aliens, bullets, bombs });
  }

  function showGameOverPopup() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = '#00ff00';
    ctx.font = '48px "Press Start 2P"';
    ctx.textAlign = 'center';
    ctx.fillText('Game Over', canvas.width / 2, canvas.height / 2 - 20);

    ctx.fillStyle = '#00ff00';
    ctx.font = '24px "Press Start 2P"';
    ctx.fillText('Click to Restart', canvas.width / 2, canvas.height / 2 + 40);

    // Remove any existing listener before adding

    // Add the event listener to handle restart
    canvas.addEventListener('click', handleRestart);
    canvas.removeEventListener('click', handleRestart);

  }

  function showNextLevelPopup() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = '#00ff00';
    ctx.font = '48px "Press Start 2P"';
    ctx.textAlign = 'center';
    ctx.fillText('Level Complete', canvas.width / 2, canvas.height / 2 - 20);

    ctx.fillStyle = '#00ff00';
    ctx.font = '24px "Press Start 2P"';
    ctx.fillText('Click to Next Level', canvas.width / 2, canvas.height / 2 + 40);

    canvas.addEventListener('click', handleNextLevel);
  }

  function showWinPopup() {
    isRunning = false;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the winImage across the entire canvas
    ctx.drawImage(winImage, 0, 0, canvas.width, canvas.height);

    ctx.fillStyle = '#00ff00';
    ctx.font = '48px "Press Start 2P"';
    ctx.textAlign = 'center';
    ctx.fillText('Congratulations!', canvas.width / 2, canvas.height / 2 - 60);

    ctx.fillStyle = '#00ff00';
    ctx.font = '24px "Press Start 2P"';
    ctx.fillText('You have completed the game!', canvas.width / 2, canvas.height / 2 - 20);
  }

  function handleRestart() {
    canvas.removeEventListener('click', handleRestart);
    onGameUpdate('restart');
  }

  function handleNextLevel() {
    canvas.removeEventListener('click', handleNextLevel);
    onGameUpdate('nextLevel');
  }

  function updateAlienPositions() {
    switch (alienMovementPattern) {
      case 'zigzag':
        aliens.forEach(alien => {
          alien.x += alienSpeed * alienDirection;
        });
        const hitEdgeZigzag = aliens.some(alien => alien.x <= 0 || alien.x + alien.width >= canvas.width - 15);
        if (hitEdgeZigzag) {
          aliens.forEach(alien => {
            alien.y += alienSize;
          });
          alienDirection *= -1;
        }
        break;
      case 'wave':
        alienStep += 0.1;
        aliens.forEach(alien => {
          alien.x += alienSpeed * alienDirection;
          alien.y += Math.sin(alienStep + alien.col) * 2;
        });
        const hitEdgeWave = aliens.some(alien => alien.x <= 0 || alien.x + alien.width >= canvas.width - 15);
        if (hitEdgeWave) {
          aliens.forEach(alien => {
            alien.y += alienSize;
          });
          alienDirection *= -1;
        }
        break;
      case 'splits':
        aliens.forEach(alien => {
          if (alien.row % 2 === 0) {
            alien.x += alienSpeed * alienDirection;
          } else {
            alien.x -= alienSpeed * alienDirection;
          }
        });
        const hitEdgeSplits = aliens.some(alien => alien.x <= 0 || alien.x + alien.width >= canvas.width - 15);
        if (hitEdgeSplits) {
          aliens.forEach(alien => {
            alien.y += alienSize;
          });
          alienDirection *= -1;
        }
        break;
      case 'crisscross':
        alienStep += 0.1;
        aliens.forEach(alien => {
          if (alien.row % 2 === 0) {
            alien.x += alienSpeed * alienDirection + Math.sin(alienStep + alien.col) * 2;
          } else {
            alien.x -= alienSpeed * alienDirection + Math.sin(alienStep + alien.col) * 2;
          }
        });
        const hitEdgeCrisscross = aliens.some(alien => alien.x <= 0 || alien.x + alien.width >= canvas.width - 15);
        if (hitEdgeCrisscross) {
          aliens.forEach(alien => {
            alien.y += alienSize;
          });
          alienDirection *= -1;
        }
        break;
      case 'circular':
        alienStep += 0.1;
        centerX += alienSpeed * alienDirection;
        aliens.forEach((alien, index) => {
          alien.x = centerX + Math.cos(alienStep + index) * 100;
          alien.y = canvas.height / 2 + Math.sin(alienStep + index) * 100;
        });
        const hitEdgeCircular = centerX - 100 <= 0 || centerX + 100 >= canvas.width - 40;
        if (hitEdgeCircular) {
          alienDirection *= -1;
        }
        break;
      case 'verticalWave':
        alienStep += 0.1;
        aliens.forEach(alien => {
          alien.y += verticalDirection * alienSpeed;
          alien.x += alienSpeed * alienDirection + Math.sin(alienStep + alien.row) * 2;
        });
        const hitEdgeVerticalWave = aliens.some(alien => alien.x <= 0 || alien.x + alien.width >= canvas.width - 15);
        if (hitEdgeVerticalWave) {
          alienDirection *= -1;
        }
        const hitVerticalEdge = aliens.some(alien => alien.y <= 0 || alien.y + alien.height >= canvas.height - 15);
        if (hitVerticalEdge) {
          verticalDirection *= -1;
        }
        break;
      case 'grid':
        aliens.forEach(alien => {
          alien.x += alienSpeed * alienDirection;
        });
        const hitEdgeGrid = aliens.some(alien => alien.x <= 0 || alien.x + alien.width >= canvas.width - 15);
        if (hitEdgeGrid) {
          aliens.forEach(alien => {
            alien.y += alienSize;
          });
          alienDirection *= -1;
        }
        break;
      default:
        break;
    }
  }

  function update() {
    if (!isRunning) return;

    player.x += player.dx;
    player.x = Math.max(0, Math.min(player.x, canvas.width - player.width - 15)); // Ensure player stays within canvas bounds

    bullets = bullets.map(bullet => ({ ...bullet, y: bullet.y - bulletSpeed })).filter(bullet => bullet.y > 0);

    bombs = bombs.map(bomb => ({ ...bomb, y: bomb.y + bombSpeed })).filter(bomb => bomb.y < canvas.height);

    updateAlienPositions();

    bullets.forEach((bullet, bulletIndex) => {
      aliens.forEach((alien, alienIndex) => {
        if (
          bullet.x < alien.x + alien.width &&
          bullet.x + bullet.width > alien.x &&
          bullet.y < alien.y + alien.height &&
          bullet.y + bullet.height > alien.y
        ) {
          const { row, col } = alien;
          onGameUpdate('alienShot', { x: alien.x, y: alien.y, row: row, col: col });
          aliens.splice(alienIndex, 1);
          bullets.splice(bulletIndex, 1);

          if (aliens.length === 0) {
            isRunning = false;
            if (levelIndex + 1 < levels.length) {
              onGameUpdate('levelComplete', { level: levelIndex });
              showNextLevelPopup();
            } else {
              showWinPopup();
              onGameUpdate('gameCompleted');
            }
          }
        }
      });
    });

    bombs.forEach((bomb, bombIndex) => {
      if (
        bomb.x < player.x + player.width &&
        bomb.x + bomb.width > player.x &&
        bomb.y < player.y + player.height &&
        bomb.y + bomb.height > player.y
      ) {
        isRunning = false;
        onGameUpdate('gameOver');
        showGameOverPopup();
      }
    });

    aliens.forEach(alien => {
      if (
        alien.x < player.x + player.width &&
        alien.x + alien.width > player.x &&
        alien.y < player.y + player.height &&
        alien.y + alien.height > player.y
      ) {
        isRunning = false;
        onGameUpdate('gameOver');
        showGameOverPopup();
      }
    });

    draw();
    requestAnimationFrame(update);
  }

  function keyDown(e) {
    if (e.key === 'ArrowRight' || e.key === 'd') {
        player.dx = playerSpeed;
    } else if (e.key === 'ArrowLeft' || e.key === 'a') {
        player.dx = -playerSpeed;
    } else if (e.key === ' ') {
        bullets.push({
            x: player.x + player.width / 1.5,  // Adjusted to the right by removing - bulletSize / 2
            y: player.y,
            width: bulletSize,
            height: bulletSize
        });
    }
  }

  function keyUp(e) {
    if (e.key === 'ArrowRight' || e.key === 'd' || e.key === 'ArrowLeft' || e.key === 'a') {
      player.dx = 0;
    }
  }

  function touchControlStart(action) {
    if (action === 'left') {
        player.dx = -playerSpeed;
    } else if (action === 'right') {
        player.dx = playerSpeed;
    } else if (action === 'fire') {
        bullets.push({
            x: player.x + player.width / 1.5,  // Adjusted to the right by removing - bulletSize / 2
            y: player.y,
            width: bulletSize,
            height: bulletSize
        });
    }
  }

  function touchControlEnd() {
    player.dx = 0;
  }

  function dropBomb() {
    if (aliens.length > 0) {
      const randomAlien = aliens[Math.floor(Math.random() * aliens.length)];
      bombs.push({ 
        x: randomAlien.x + alienSize / 2 - bulletSize / 2, 
        y: randomAlien.y + alienSize, 
        width: bulletSize, 
        height: bulletSize, 
        row: randomAlien.row, 
        col: randomAlien.col 
      });
    }
  }

  function startGameLoop() {
    isRunning = true;
    update();
    setInterval(dropBomb, bombFrequency);
  }

  createAliens();
  startGameLoop();

  window.addEventListener('keydown', keyDown);
  window.addEventListener('keyup', keyUp);

  document.getElementById('leftButton').addEventListener('touchstart', () => touchControlStart('left'));
  document.getElementById('rightButton').addEventListener('touchstart', () => touchControlStart('right'));
  document.getElementById('fireButton').addEventListener('touchstart', () => touchControlStart('fire'));

  document.getElementById('leftButton').addEventListener('touchend', touchControlEnd);
  document.getElementById('rightButton').addEventListener('touchend', touchControlEnd);
}
