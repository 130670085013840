import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import RetroButton from './RetroButton';
import './ReferralsPage.css'; // Make sure this import points to the correct file

const ReferralsPage = () => {
    const [address, setAddress] = useState('');
    const [referralLink, setReferralLink] = useState('');
    const [copySuccess, setCopySuccess] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (event) => {
        setAddress(event.target.value);
        setErrorMessage(''); // Clear the error message when the user starts typing
    };

    const generateReferralLink = () => {
        if (isValidEthereumAddress(address)) {
            setReferralLink(`https://orbitaloctop.us/referral/${address}`);
        } else {
            setErrorMessage('Please enter a valid Ethereum address.');
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 2000);
        }, () => {
            setCopySuccess('Failed to copy!');
            setTimeout(() => setCopySuccess(''), 2000);
        });
    };

    function isValidEthereumAddress(address) {
        return /^0x[a-fA-F0-9]{40}$/.test(address);
    }

    return (
        <div className="referral-page">
            <Navbar />
            <div className="content1">
                <h1 className="title">Referral Program</h1>
                <p className="description">Earn more by referring others to the Orbital Octopus Airdrop</p>
                <p>Enter your referral link to get started!</p>
                <div className="referral-details">
                    <p>Eligible wallets will have 30 days to claim after clicking your link.</p>
                    <p>For each referral, you will receive 1% of the total airdrop amount.</p>
                    <p>The referrer's wallet does not need to be eligible to claim the airdrop in order to collect rewards. All wallets can collect rewards.</p>
                </div>
                <div className="form">
                    <div className="input-group">
                        <input
                            type="text"
                            value={address}
                            onChange={handleInputChange}
                            placeholder="Enter your Ethereum address"
                        />
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                    <RetroButton onClick={generateReferralLink}>Generate Referral Link</RetroButton>
                </div>
                {referralLink && (
                    <div className="referral-link-container">
                        <p>Your Referral Link:</p>
                        <p className="referral-link">{referralLink}</p>
                        <RetroButton className="copy-button" onClick={copyToClipboard}>Copy to Clipboard</RetroButton>
                        {copySuccess && <p>{copySuccess}</p>}
                    </div>
                )}
                <div className="url-shorteners">
                    <p>Use URL Shorteners</p>
                    <p>You can use URL shorteners like <a href="https://tinyurl.com" target="_blank" rel="noopener noreferrer">tinyurl.com</a> to make your referral links easier to share.</p>
                </div>
                <div className="back-button-container">
                    <Link to="/airdrop">
                        <RetroButton>BACK TO AIRDROP</RetroButton>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ReferralsPage;
