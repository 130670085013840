export const levels = [
  {
    levelNumber: 1,
    rows: 7,
    cols: 10,
    alienSize: 20,
    playerSize: 30,
    bulletSize: 5,
    playerSpeed: 5,
    bulletSpeed: 7,
    bombSpeed: 3,
    bombFrequency: 2000, // Frequency in milliseconds
    alienMovementPattern: 'zigzag',
  },
  {
    levelNumber: 2,
    rows: 5,
    cols: 8,
    alienSize: 25,
    playerSize: 30,
    bulletSize: 6,
    playerSpeed: 5,
    bulletSpeed: 8,
    bombSpeed: 5,
    bombFrequency: 1500, // Frequency in milliseconds
    alienMovementPattern: 'wave',
  },
  {
    levelNumber: 3,
    rows: 6,
    cols: 9,
    alienSize: 20,
    playerSize: 30,
    bulletSize: 5,
    playerSpeed: 5,
    bulletSpeed: 7,
    bombSpeed: 3,
    bombFrequency: 1000, // Frequency in milliseconds
    alienMovementPattern: 'splits',
  },
  {
    levelNumber: 4,
    rows: 7,
    cols: 10,
    alienSize: 20,
    playerSize: 22,
    bulletSize: 5,
    playerSpeed: 8,
    bulletSpeed: 7,
    bombSpeed: 5,
    bombFrequency: 600, // Frequency in milliseconds
    alienMovementPattern: 'circular',
  },
  {
    levelNumber: 5,
    rows: 8,
    cols: 14,
    alienSize: 20,
    playerSize: 30,
    bulletSize: 5,
    playerSpeed: 5,
    bulletSpeed: 7,
    bombSpeed: 6,
    bombFrequency: 800, // Frequency in milliseconds
    alienMovementPattern: 'splits',
  },
  {
    levelNumber: 6,
    rows: 8,
    cols: 14,
    alienSize: 20,
    playerSize: 35,
    bulletSize: 5,
    playerSpeed: 4,
    bulletSpeed: 5,
    bombSpeed: 6,
    bombFrequency: 700, // Frequency in milliseconds
    alienMovementPattern: 'zigzag',
  },
  {
    levelNumber: 7,
    rows: 10,
    cols: 20,
    alienSize: 10,
    playerSize: 20,
    bulletSize: 5,
    playerSpeed: 5,
    bulletSpeed: 7,
    bombSpeed: 10,
    bombFrequency: 600, // Frequency in milliseconds
    alienMovementPattern: 'wave',
  },
  {
    levelNumber: 8,
    rows: 10,
    cols: 20,
    alienSize: 10,
    playerSize: 20,
    bulletSize: 10,
    playerSpeed: 2,
    bulletSpeed: 4,
    bombSpeed: 10,
    bombFrequency: 500, // Frequency in milliseconds
    alienMovementPattern: 'splits',
  },
  {
    levelNumber: 9,
    rows: 10,
    cols: 30,
    alienSize: 20,
    playerSize: 30,
    bulletSize: 5,
    playerSpeed: 20,
    bulletSpeed: 7,
    bombSpeed: 10,
    bombFrequency: 400, // Frequency in milliseconds
    alienMovementPattern: 'circular',
  },
  {
    levelNumber: 10,
    rows: 8,
    cols: 15,
    alienSize: 20,
    playerSize: 10,
    bulletSize: 10,
    playerSpeed: 20,
    bulletSpeed: 10,
    bombSpeed: 10,
    bombFrequency: 500, // Frequency in milliseconds
    alienMovementPattern: 'verticalWave',
  }
];
