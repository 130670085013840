import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import AirdropPage from './components/AirdropPage';
import Footer from './components/Footer';
import ReferralsPage from './components/ReferralsPage';
import KickbackCalculator from './components/KickbackCalculator';

const App = () => {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

const MainApp = () => {
  const location = useLocation();
  const [scrollSnap, setScrollSnap] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [toggleFooter, setToggleFooter] = useState(false); // Tracks toggle background button press

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
  }, [location.pathname]);

  useEffect(() => {
    const noSnapPaths = ['/airdrop'];
    if (noSnapPaths.includes(location.pathname)) {
      setScrollSnap(false);
    } else {
      setScrollSnap(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Always show footer on non-home routes
    if (location.pathname === '/' && toggleFooter) {
      setShowFooter(false); // Hide footer only if we're on the home page and toggle is active
    } else {
      setShowFooter(true); // Show footer on all other pages
    }
  }, [location, toggleFooter]);

  return (
    <div className={`screen-container ${scrollSnap ? 'snap-enabled' : 'snap-disabled'}`}>
      <Navbar toggleVisibility={() => setToggleFooter(!toggleFooter)} /> {/* Pass the toggle function to Navbar */}
      <main className="main-content">
        <Routes>
          <Route 
            exact path="/" 
            element={<Home setShowFooter={setShowFooter} />} // Pass setShowFooter to control footer visibility
          />
          <Route exact path="/referral" element={<ReferralsPage />} />
          <Route exact path="/referral/:referrerAddress?" element={<Home setShowFooter={setShowFooter} />} />
          <Route path="/airdrop" element={<AirdropPage />} />
          <Route path="/kickback-calculator" element={<KickbackCalculator />} />
        </Routes>
      </main>
      {showFooter && <Footer />} {/* Conditionally render the footer based on showFooter state */}
      <SpeedInsights />
      <Analytics />
    </div>
  );
}

const Home = ({ setShowFooter }) => {
  const { referrerAddress } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (referrerAddress) {
      Cookies.set('referrerAddress', referrerAddress, { expires: 7 });
      console.log('Cookie set for referrer:', Cookies.get('referrerAddress'));
    }
  }, [referrerAddress, location]);

  return (
    <>
      <LandingPage setShowFooter={setShowFooter} /> {/* Pass setShowFooter to LandingPage */}
    </>
  );
}

export default App;
