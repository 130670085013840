import React from 'react';
import './RetroButton.css';

const RetroButton = ({ onClick, children }) => {
    return (
        <button className="retro-button" onClick={onClick}>
            {children}
        </button>
    );
};

export default RetroButton;
