import React, { useEffect, useRef, useState } from 'react';
import './LandingPage.css';
import { Chart, registerables } from 'chart.js';
import { startSpaceInvaders } from '../game/spaceInvaders';
import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';
import alienImage from '../assets/orbitalOctopus.png';
import alienImage2 from '../assets/orbitalOctopus2.png';
import finalImageSrc from '../assets/glman123_space_background_nova_color_00ff00_only_stars_--ar_3_363c3d8f-456d-4bde-b73a-a817b7c80b97_3.png';
import { levels } from '../game/levels';
import NewsSection from './NewsSection';

// Register Chart.js components
Chart.register(...registerables);

// Token contract address
const TOKEN_CONTRACT_ADDRESS = '0x6bB56dA93DAd8312eE1FcEBd07D55D61b33B9A7c';

const LandingPage = ({ setShowFooter }) => {
  const canvasRef = useRef(null);
  const gameSectionRef = useRef(null);
  const pieChartRef = useRef(null);
  const tokenomicsPieChartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const tokenomicsChartInstanceRef = useRef(null);
  const imageContainerRef = useRef(null);
  const location = useLocation();
  const [alienMatrix, setAlienMatrix] = useState([]);
  const [playerPosition, setPlayerPosition] = useState({ x: 0, y: 0 });
  const [showBackgroundOnly, setShowBackgroundOnly] = useState(false);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [levelComplete, setLevelComplete] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [showClipboardPopup, setShowClipboardPopup] = useState(false);
  const [imageKey, setImageKey] = useState(Date.now()); // Key for re-rendering alienImage2

  useEffect(() => {
    const canvas = canvasRef.current;

    // Disable right-click, copy-paste, and text selection on the canvas
    const disableContextAndCopyPaste = (e) => e.preventDefault();
    canvas.addEventListener('contextmenu', disableContextAndCopyPaste);
    canvas.addEventListener('copy', disableContextAndCopyPaste);
    canvas.addEventListener('paste', disableContextAndCopyPaste);
    canvas.addEventListener('cut', disableContextAndCopyPaste);
    canvas.addEventListener('selectstart', disableContextAndCopyPaste);

    // Prevent zooming and other default touch behaviors on the canvas
    const preventTouchZoom = (e) => {
      if (e.touches.length > 1) e.preventDefault();
    };
    canvas.addEventListener('touchstart', preventTouchZoom);

    // Cleanup event listeners on unmount
    return () => {
      canvas.removeEventListener('contextmenu', disableContextAndCopyPaste);
      canvas.removeEventListener('copy', disableContextAndCopyPaste);
      canvas.removeEventListener('paste', disableContextAndCopyPaste);
      canvas.removeEventListener('cut', disableContextAndCopyPaste);
      canvas.removeEventListener('selectstart', disableContextAndCopyPaste);
      canvas.removeEventListener('touchstart', preventTouchZoom);
    };
  }, []);

  useEffect(() => {
    setShowFooter(!showBackgroundOnly); // Update footer visibility based on showBackgroundOnly
  }, [showBackgroundOnly, setShowFooter]);

  useEffect(() => {
    if (location.hash === '#game' && gameSectionRef.current) {
      gameSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const initializeMatrix = (level, blackedOut = false) => {
    return Array.from({ length: levels[level].rows }, () =>
      Array.from({ length: levels[level].cols }, () => (blackedOut ? 1 : 0))
    );
  };

  useEffect(() => {
    setAlienMatrix(initializeMatrix(currentLevel));
  }, [currentLevel]);

  const initializeCharts = () => {
    const screenWidth = window.innerWidth;

    if (pieChartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const ctx = pieChartRef.current.getContext('2d');
      const chartSize = screenWidth < 768 ? screenWidth * 0.8 : 400;

      pieChartRef.current.width = chartSize;
      pieChartRef.current.height = chartSize;

      chartInstanceRef.current = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Initial Liquidity', 'Team and Development', 'Marketing and Community Incentives, Burn, etc.', 'Airdrops'],
          datasets: [{
            label: 'Tokenomics',
            data: [10, 20, 30, 40], // Values in trillions for better visualization
            backgroundColor: ['#094f09', '#aef20e', '#ebf707', '#00ff00'],
            borderColor: '#000',
            borderWidth: 2,
            hoverOffset: 4
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                font: {
                  family: 'Press Start 2P',
                  size: 20,
                  weight: 'bold',
                },
                color: '#00ff00'
              }
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.label + ': ' + tooltipItem.raw + ' trillion tokens';
                }
              }
            }
          }
        },
        plugins: [{
          id: 'percentageLabels',
          afterDraw: chart => {
            const ctx = chart.ctx;
            chart.data.datasets.forEach((dataset, i) => {
              const meta = chart.getDatasetMeta(i);
              meta.data.forEach((element, index) => {
                ctx.save();
                ctx.fillStyle = '#ffffff';
                ctx.font = 'bold 14px "Press Start 2P"';
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';

                const data = dataset.data[index];
                const position = element.tooltipPosition();
                ctx.fillText(data + '%', position.x, position.y);
                ctx.restore();
              });
            });
          }
        }]
      });
    }

    if (tokenomicsPieChartRef.current) {
      if (tokenomicsChartInstanceRef.current) {
        tokenomicsChartInstanceRef.current.destroy();
      }

      const ctx = tokenomicsPieChartRef.current.getContext('2d');
      const chartSize = screenWidth < 768 ? screenWidth * 0.8 : 400;

      tokenomicsPieChartRef.current.width = chartSize;
      tokenomicsPieChartRef.current.height = chartSize;

      tokenomicsChartInstanceRef.current = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Initial Liquidity', 'Team and Development', 'Marketing and Community Incentives, Burn, etc.', 'Airdrops'],
          datasets: [{
            label: 'Tokenomics',
            data: [10, 20, 30, 40], // Values in trillions for better visualization
            backgroundColor: ['#094f09', '#aef20e', '#ebf707', '#00ff00'],
            borderColor: '#000',
            borderWidth: 2,
            hoverOffset: 4
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                font: {
                  family: 'Press Start 2P',
                  size: 20,
                  weight: 'bold',
                },
                color: '#00ff00'
              }
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.label + ': ' + tooltipItem.raw + ' trillion tokens';
                }
              }
            }
          }
        },
        plugins: [{
          id: 'percentageLabels',
          afterDraw: chart => {
            const ctx = chart.ctx;
            chart.data.datasets.forEach((dataset, i) => {
              const meta = chart.getDatasetMeta(i);
              meta.data.forEach((element, index) => {
                ctx.save();
                ctx.fillStyle = '#ffffff';
                ctx.font = 'bold 14px "Press Start 2P"';
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';

                const data = dataset.data[index];
                const position = element.tooltipPosition();
                ctx.fillText(data + '%', position.x, position.y);
                ctx.restore();
              });
            });
          }
        }]
      });
    }
  };

  useEffect(() => {
    if (!showBackgroundOnly) {
      initializeCharts();
      setImageKey(Date.now()); // Change key to force re-render of alienImage2
    }
  }, [showBackgroundOnly]);

  const handleGameUpdate = (eventType, data) => {
    if (eventType === 'alienShot') {
      const { row, col } = data;

      if (row >= 0 && row < levels[currentLevel].rows && col >= 0 && col < levels[currentLevel].cols) {
        setAlienMatrix((prevMatrix) => {
          const newMatrix = prevMatrix.map((r) => [...r]);
          newMatrix[row][col] = 1;
          return newMatrix;
        });
      }
    } else if (eventType === 'update') {
      setPlayerPosition((prevPosition) => ({ ...prevPosition, y: data.player.y }));
    } else if (eventType === 'gameOver') {
      setIsGameRunning(false);
      setGameOver(true);
    } else if (eventType === 'levelComplete') {
      setIsGameRunning(false);
      setLevelComplete(true);
      const nextLevel = currentLevel + 1;
      setCurrentLevel(nextLevel);
    } else if (eventType === 'gameCompleted') {
      setIsGameRunning(false);
      setGameCompleted(true); // Set gameCompleted to true
    } else if (eventType === 'restart') {
      startGame(currentLevel);
    }
  };

  useEffect(() => {
    if (gameCompleted) {
      drawFinalWinImage();
    }
  }, [gameCompleted]);

  const drawFinalWinImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const finalImage = new Image();
      finalImage.src = finalImageSrc;
  
      // Ensure the image is loaded before drawing it
      finalImage.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(finalImage, 0, 0, canvas.width, canvas.height);
  
        // Draw the first message
        ctx.fillStyle = '#00ff00';
        ctx.font = '48px "Press Start 2P"';
        ctx.textAlign = 'center';
        ctx.fillText('Congratulations!', canvas.width / 2, canvas.height / 2 - 60);
  
        // Adjust the font size for the first message
        ctx.font = '24px "Press Start 2P"'; // Font size for message1
        const message1 = 'You have completed the game!';
        ctx.fillText(message1, canvas.width / 2, canvas.height / 2 - 20);
  
        // Change the font size for the second message
        ctx.font = '19px "Press Start 2P"'; // Smaller font size for message2
        const message2 = 'Check back for new levels in the future!';
        ctx.fillText(message2, canvas.width / 2, canvas.height / 2 + 20); // Adjust Y position for second line
      };
  
      // Handle image loading errors
      finalImage.onerror = () => {
        console.error('Failed to load the winning image.');
      };
    }
  };   

  const toggleVisibility = () => {
    setShowBackgroundOnly(!showBackgroundOnly);

    if (!showBackgroundOnly) {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const startGame = (level = currentLevel) => {
    setIsGameRunning(true);
    setGameOver(false);
    setLevelComplete(false);
    setGameCompleted(false);
    setAlienMatrix(initializeMatrix(level));

    const canvas = canvasRef.current;
    if (canvas) {
      startSpaceInvaders(canvas, handleGameUpdate, level);
    }
  };

  const renderBackgroundElements = () => {
    if (currentLevel >= levels.length) return null; // Avoid errors when all levels are completed
  
    const elements = [];
    const containerWidth = window.innerWidth;
    const containerHeight = window.innerHeight;
  
    // Adjust spacing based on screen width
    const isSmallScreen = containerWidth < 768;
    
    // Reduce the size of the aliens on smaller screens
    const elementWidth = isSmallScreen ? Math.floor(containerWidth / (levels[currentLevel].cols * 1)) : Math.floor(containerWidth / levels[currentLevel].cols);
    const elementHeight = isSmallScreen ? Math.floor(containerHeight / (levels[currentLevel].rows * 2)) : Math.floor(containerHeight / levels[currentLevel].rows);
  
    for (let i = 0; i < levels[currentLevel].rows; i++) {
      for (let j = 0; j < levels[currentLevel].cols; j++) {
        const isBlackedOut = alienMatrix[i] && alienMatrix[i][j] === 1;
        const top = i * elementHeight + 50; // Added 50px to move elements down
        const left = j * elementWidth;
  
        elements.push(
          <div
            key={`${i}-${j}`}
            className="background-element"
            style={{
              top: `${top}px`,
              left: `${left}px`,
              width: `${elementWidth}px`,
              height: `${elementHeight}px`,
              backgroundColor: isBlackedOut ? 'transparent' : '',
              backgroundImage: isBlackedOut ? '' : `url(${alienImage})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              margin: 0,
              padding: 0,
              border: 'none',
              boxSizing: 'border-box',
            }}
          ></div>
        );
      }
    }
    return elements;
  };
  

  useEffect(() => {
    const backgroundElements = document.querySelectorAll('.background-element');
    
    if (!backgroundElements || backgroundElements.length === 0) {
      console.warn('No background elements found.');
      return;
    }
  
    backgroundElements.forEach((element) => {
      const randomDelay = Math.random() * 5;
      const randomDuration = 20 + Math.random() * 10;
      element.style.animationDelay = `${randomDelay}s`;
      element.style.animationDuration = `${randomDuration}s`;
    });
  }, [alienMatrix]);
  

  useEffect(() => {
    const gridItem = imageContainerRef.current;
    const octopus = gridItem.querySelector('.grid-image');

    const moveOctopus = () => {
      const gridItemWidth = gridItem.offsetWidth;
      const gridItemHeight = gridItem.offsetHeight;
      const maxX = gridItemWidth - octopus.offsetWidth;
      const maxY = gridItemHeight - octopus.offsetHeight;

      const randomX = Math.random() * maxX;
      const randomY = Math.random() * maxY;

      octopus.style.transform = `translate(${randomX}px, ${randomY}px)`;
    };

    const intervalId = setInterval(moveOctopus, 2000);

    gridItem.addEventListener('mousemove', (e) => {
      const rect = gridItem.getBoundingClientRect();
      const x = e.clientX - rect.left - octopus.offsetWidth / 2;
      const y = e.clientY - rect.top - octopus.offsetHeight / 2;
      octopus.style.transform = `translate(${x}px, ${y}px)`;
    });

    return () => {
      clearInterval(intervalId);
      gridItem.removeEventListener('mousemove', moveOctopus);
    };
  }, [imageKey]); // Re-run when imageKey changes

  const copyToClipboard = () => {
    navigator.clipboard.writeText(TOKEN_CONTRACT_ADDRESS);
    setShowClipboardPopup(true);
    setTimeout(() => {
      setShowClipboardPopup(false);
    }, 3000);
  };

  return (
    <div className={`landing-page ${showBackgroundOnly ? 'background-only' : ''}`}>
      <Navbar toggleVisibility={toggleVisibility} />

      <div className="content">
        <h1 className="title">Orbital Octopus</h1>
        <NewsSection />
      </div>
      {/* Contract Address Section in between */}
      <div className="contract-address" onClick={copyToClipboard}>
        <p className="contract-label">OOPS Contract Address:</p>
        <p>{TOKEN_CONTRACT_ADDRESS}</p>
      </div>

      {showClipboardPopup && (
        <div className="clipboard-popup">
          Contract address copied to clipboard!
        </div>
      )}
      
      {/* Hide this section when background-only is true */}
      {!showBackgroundOnly && (
        <>
          <div className="grid-container">
            <div className="grid-item">
              <h2 className="grid-title">Features</h2>
              <ul className="grid-list">
                <li>Fair Launch with Airdrop</li>
                <li>Passive Income with Kickback System</li>
                <li>5% Token Fee Guarantees Liquidity Forever</li>
                <li>Future NFT Airdrops</li>
              </ul>
            </div>
            <div className="grid-item image-container" ref={imageContainerRef}>
              <img src={alienImage2} alt="Example" className="grid-image" key={imageKey} />
            </div>
            <div className="grid-item">
              <h2 className="grid-title">Tokenomics</h2>
              <canvas ref={pieChartRef} id="pieChart"></canvas>
            </div>
          </div>

          <div className="airdrop-info">
            <div className="airdrop-content-container">
              <div className="airdrop-section">
                <div className="airdrop-text">
                  <h2 className="airdrop-title">Why an Airdrop</h2>
                  <p className="airdrop-steps">
                    We know there are a lot of scams and rugpulls out there in the crypto world. 
                    We aim to remedy this by having a fair launch with an airdrop. Distribute the OOPS tokens to a wide audience, 
                    increase project awareness, reward early supporters, and grow the community organically.
                  </p>
                </div>
              </div>

              <div className="airdrop-section">
                <div className="airdrop-text">
                  <h2 className="airdrop-title">Eligibility</h2>
                  <p className="airdrop-steps">
                    To qualify for the Orbital Octopus airdrop, you simply need to have interacted with the Base chain in any way that used gas. 
                    This include transactions, contract interactions, or any activity that required gas on the Base network. 
                    There are no specific token requirements any valid interaction with the Base chain qualifies you for the airdrop.
                  </p>
                </div>
              </div>

              <div className="airdrop-section">
                <div className="airdrop-text centered-text">
                  <p className="airdrop-description">
                    If you want more info on how the airdrop works, visit:
                    <a href="https://8bit-nft.gitbook.io/orbital-octopus/airdrop" className="gitbook-link" target="_blank" rel="noopener noreferrer">
                      Orbital Octopus Airdrop Docs
                    </a>.
                  </p>
                </div>
              </div>

              <div className="airdrop-learn-more">
                <button className="learn-more-button" onClick={() => window.location.href='/airdrop'}>Airdrop Page</button>
              </div>
            </div>
          </div>

          {/* Kickback System Section */}
          <div className="kickback-info">
            <div className="kickback-content-container">
              <div className="kickback-section">
                <div className="kickback-text">
                  <h2 className="kickback-title">Kickback System</h2>
                  <p className="kickback-steps">
                    The Orbital Octopus Kickback System allows users to earn rewards based on transaction volume. 
                    The more transactions that occur, the greater the rewards. It is a system designed to reward holders who believe in the project and 
                    contribute to its long-term success. The Kickback System is automatically applied and accumulates over time, giving users the ability 
                    to earn passive income simply by holding OOPS.
                  </p>
                </div>
              </div>

              <div className="kickback-section">
                <div className="kickback-text">
                  <h2 className="kickback-title">How It Works</h2>
                  <p className="kickback-steps">
                    A percentage of each transaction is collected and redistributed among holders. 
                    The amount you receive depends on the size of your holding relative to the total supply. 
                    The more you hold, the more you earn. This system incentivizes holding and discourages selling, 
                    as larger holdings lead to higher rewards over time.
                  </p>
                </div>
              </div>

              <div className="kickback-section">
                <div className="kickback-text centered-text">
                  <p className="kickback-description">
                    For more details about the Kickback System, visit:
                    <a href="https://8bit-nft.gitbook.io/orbital-octopus/features/kickback-mechanism" className="gitbook-link" target="_blank" rel="noopener noreferrer">
                      Orbital Octopus Kickback Mechanism Docs
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Tokenomics Section */}
          <div className="tokenomics-info">
            <div className="tokenomics-content-container">
              <div className="tokenomics-section">
                <div className="tokenomics-text">
                  <h2 className="tokenomics-title">Tokenomics</h2>
                  <ul className="tokenomics-list">
                    <li><strong>Total Supply:</strong> <span className="white-text">1 Quadrillion</span></li>
                    <li><strong>Initial Liquidity:</strong> <span className="white-text">10%</span></li>
                    <li><strong>Team and Development:</strong> <span className="white-text">20%</span></li>
                    <li><strong>Marketing and Community Incentives, Burn, etc.:</strong> <span className="white-text">30%</span></li>
                    <li><strong>Airdrops:</strong> <span className="white-text">40%</span></li>
                    <li><strong>Fee Structure:</strong></li>
                    <ul>
                      <li><span className="white-text">2%</span>: Kickback to holders</li>
                      <li><span className="white-text">3%</span>: Added to liquidity</li>
                    </ul>
                  </ul>
                </div>
              </div>

              <div className="tokenomics-section">
                <canvas ref={tokenomicsPieChartRef} id="tokenomicsPieChart"></canvas>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={`game-section ${showBackgroundOnly ? 'background-only-game' : ''}`} ref={gameSectionRef} id="game">
        <h2 className="section-title">Play the Game</h2>
        <canvas ref={canvasRef} id="gameCanvas" width="800" height="400" tabIndex="0"></canvas>
        {!isGameRunning && !gameOver && !levelComplete && !gameCompleted && (
          <button className="start-button" onClick={() => startGame(currentLevel)}>Start Game</button>
        )}
        {gameOver && (
          <button className="start-button" onClick={() => startGame(currentLevel)}>Restart Level</button>
        )}
        {levelComplete && !gameCompleted && (
          <button className="start-button" onClick={() => startGame(currentLevel)}>Next Level</button>
        )}
        <div className="touch-controls">
          <button id="leftButton">Left</button>
          <button id="rightButton">Right</button>
          <button id="fireButton">Fire</button>
        </div>
      </div>

      {!gameOver && !levelComplete && !gameCompleted && (
        <div className="player-container"></div>
      )}
      {renderBackgroundElements()}
    </div>
  );
};

export default LandingPage;
