/* global BigInt */
import React, { useState, useEffect } from "react";
import Web3Modal from "web3modal";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import "./AirdropPage.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import AirdropABI from "../Airdrop.json";
import MemeCoinABI from "../OrbitalOctopus.json";
import Navbar from "./Navbar";
import RetroButton from "./RetroButton";
import Notification from "./Notification";
import TypedText from "./TypedText"; // Make sure this import is correct and points to the right file

function isValidEthereumAddress(address) {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}

const AirdropPage = () => {
  const TOTAL_SUPPLY = BigInt(400000000000000 * 10 ** 9); // Total supply of the airdrop tokens
  const CLAIMABLE_AMOUNT = BigInt(100000000000 * 10 ** 9); // Amount claimable per user
  const CLAIMABLE_AMOUNT_NOWEI = BigInt(100000000000); // Amount claimable per user
  const MEME_COIN_ADDRESS = "0x6bB56dA93DAd8312eE1FcEBd07D55D61b33B9A7c"; // MemeCoin contract address
  const CORRECT_CHAIN_ID = 8453; // Base chain ID

  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);
  const [account, setAccount] = useState("");
  const [airdropContract] = useState("0xfa948e435939d440ad51bA8501567D1585ea4981");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [lastClaimTimestamp, setLastClaimTimestamp] = useState(0);
  const [vestedAmount, setVestedAmount] = useState(0);
  const [amountFreeToClaim, setAmountFreeToClaim] = useState(0);
  const [remainingTokens, setRemainingTokens] = useState(0);
  const [percentageLeft, setPercentageLeft] = useState(0);
  const [allTokensClaimed, setAllTokensClaimed] = useState(false);
  const [hasClaimed, setHasClaimed] = useState(false);
  const [wrongChain, setWrongChain] = useState(false); // Added state for wrong chain
  const [isEligible, setIsEligible] = useState(true); // Add state for eligibility
  const [referrer, setReferrer] = useState(Cookies.get("referrerAddress"));
  const [balance, setBalance] = useState(null); // Updated to null to allow for animation
  const [notification, setNotification] = useState(null);
  const [showClaimVested, setShowClaimVested] = useState(true);
  const [dynamicFontSize, setDynamicFontSize] = useState(20);

  const adjustFontSize = () => {
    const width = window.innerWidth;  
    if (width < 600) {
      setDynamicFontSize(9);
    } else if (width >= 600 && width < 1024) {
      setDynamicFontSize(11);
    } else {
      setDynamicFontSize(20);
    }
  };
  
  useEffect(() => {
    adjustFontSize(); // Adjust font size on initial load
  
    const handleResize = () => {
      adjustFontSize(); // Adjust font size whenever window is resized
    };
  
    window.addEventListener("resize", handleResize); // Attach resize listener
  
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);
  

  useEffect(() => {
    const refCookie = Cookies.get("referrerAddress");
    if (refCookie !== referrer) {
      setReferrer(refCookie);
    }
  }, []);

  useEffect(() => {
    if (provider) {
      provider.on("accountsChanged", (accounts) => {
        setAccount(accounts[0] || "");
        if (!accounts.length) {
          disconnectWallet();
        }
      });
      provider.on("chainChanged", (chainId) => {
        if (parseInt(chainId, 10) !== CORRECT_CHAIN_ID) {
          setWrongChain(true);
        } else {
          setWrongChain(false);
        }
      });
    }
  }, [provider]);

  const promptSwitchChain = async () => {
    try {
      await provider.send("wallet_switchEthereumChain", [
        { chainId: `0x${CORRECT_CHAIN_ID.toString(16)}` },
      ]);
      setWrongChain(false);
    } catch (switchError) {
      if (switchError.code === 4902) {
        console.log("Chain not found, please add it to your wallet.");
      } else {
        console.error("Failed to switch network:", switchError);
        setWrongChain(true);
      }
    }
  };

  const connectWallet = async () => {
    try {
      const web3Modal = new Web3Modal();
      const instance = await web3Modal.connect();
      const provider = new Web3Provider(instance);
      setProvider(provider);
      const signer = provider.getSigner();
      const accounts = await provider.listAccounts();
      const chainId = await signer.getChainId();
      if (accounts.length > 0) {
        const firstAccount = accounts[0];
        setAccount(firstAccount);
        setSelectedAccount(firstAccount);

        if (chainId !== CORRECT_CHAIN_ID) {
          setWrongChain(true);
          promptSwitchChain();
        } else {
          setWrongChain(false);
        }

        const contractInstance = new ethers.Contract(
          airdropContract,
          AirdropABI.abi,
          signer
        );
        setContract(contractInstance);
        await fetchMemeCoinBalance(signer, firstAccount);
        await fetchLastClaimTimestamp(contractInstance, firstAccount);
        await fetchVestedAmount(contractInstance, firstAccount);
        await fetchRemainingTokens(contractInstance);
        await checkIfClaimed(contractInstance, firstAccount);
      }
    } catch (error) {
      console.error("Wallet connection failed:", error);
    }
  };

  const fetchMemeCoinBalance = async (signer, account) => {
    try {
      const memeCoinContract = new ethers.Contract(
        MEME_COIN_ADDRESS,
        MemeCoinABI.abi,
        signer
      );
      const balance = await memeCoinContract.balanceOf(account);
      setBalance(Number(balance) / 10 ** 9); // Assuming MemeCoin has 9 decimals
    } catch (error) {
      console.error("Failed to fetch Orbital Octopus balance:", error);
    }
  };

  const disconnectWallet = async () => {
    setProvider(null);
    setContract(null);
    setAccount("");
    setSelectedAccount("");
  };

  const fetchLastClaimTimestamp = async (contractInstance, account) => {
    if (contractInstance && account) {
      try {
        const timestamp = await contractInstance.getLastClaimTimestamp(account);
        setLastClaimTimestamp(timestamp);
      } catch (error) {
        console.error("Error fetching last claim timestamp:", error);
        setLastClaimTimestamp(0);
      }
    }
  };

  const fetchVestedAmount = async (contractInstance, account) => {
    if (contractInstance && account) {
      try {
        const amount = await contractInstance.getVestedTokens(account);
        setVestedAmount(BigInt(amount.toString()));
      } catch (error) {
        console.error("Error fetching vested amount:", error);
        setVestedAmount(0);
      }
    }
  };

  const fetchRemainingTokens = async (contractInstance) => {
    if (contractInstance) {
      try {
        const totalClaimedTokens =
          await contractInstance.getTotalClaimedTokens();

        const remainingTokens =
          TOTAL_SUPPLY - BigInt(totalClaimedTokens.toString());

        setRemainingTokens(remainingTokens);

        const percentageLeftBigInt =
          (remainingTokens * BigInt(1000000)) / TOTAL_SUPPLY;

        const percentageLeft = Number(percentageLeftBigInt) / 10000;
        setPercentageLeft(percentageLeft);

        setAllTokensClaimed(remainingTokens < CLAIMABLE_AMOUNT);
      } catch (error) {
        console.error("Error fetching remaining tokens:", error);
        setRemainingTokens(0);
        setPercentageLeft(0);
      }
    }
  };

  const checkIfClaimed = async (contractInstance, account) => {
    if (contractInstance && account) {
      try {
        const claimed = await contractInstance.claimed(account); // Check the claimed mapping
        setHasClaimed(claimed);
      } catch (error) {
        console.error("Error checking if tokens have been claimed:", error);
        setHasClaimed(false);
      }
    }
  };

  useEffect(() => {
    const calculateAmountFreeToClaim = async () => {
      try {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeSinceLastClaim =
          Number(currentTime) - Number(lastClaimTimestamp);
        const ONE_HOUR_IN_SECONDS = 1 * 60 * 60;
        const SEVEN_DAYS_IN_SECONDS = 7 * 24 * 60 * 60;
        const SIX_MONTHS_IN_SECONDS = 26 * 7 * 24 * 60 * 60; // 6 months = 26 weeks

        const feePercentage = 5;
        const unlockableTokens = (Number(vestedAmount) * timeSinceLastClaim) / SIX_MONTHS_IN_SECONDS;
        const feeAmount = unlockableTokens * (feePercentage / 100);
        const unlockableAmountAfterFee = unlockableTokens - feeAmount;
        const amountFreeToClaim = Math.min(unlockableAmountAfterFee, Number(vestedAmount)) / 10 ** 9;
        setAmountFreeToClaim(amountFreeToClaim);
      } catch (error) {
        console.error("Error calculating amount free to claim:", error);
        setAmountFreeToClaim(0);
      }
    };
    calculateAmountFreeToClaim();
  }, [lastClaimTimestamp, vestedAmount]);

  useEffect(() => {
    const initPercentageLeft = async () => {
      try {
        const web3Modal = new Web3Modal();
        const instance = await web3Modal.connect();
        const provider = new Web3Provider(instance);
        const contractInstance = new ethers.Contract(
          airdropContract,
          AirdropABI.abi,
          provider
        );
        const totalClaimedTokens =
          await contractInstance.getTotalClaimedTokens();

        const remainingTokens =
          TOTAL_SUPPLY - BigInt(totalClaimedTokens.toString());

        setRemainingTokens(remainingTokens);

        const percentageLeftBigInt =
          (remainingTokens * BigInt(1000000)) / TOTAL_SUPPLY;

        const percentageLeft = Number(percentageLeftBigInt) / 10000;
        setPercentageLeft(percentageLeft);

        setAllTokensClaimed(remainingTokens < CLAIMABLE_AMOUNT);
      } catch (error) {
        console.error("Error fetching remaining tokens:", error);
        setRemainingTokens(0);
        setPercentageLeft(0);
      }
    };
    initPercentageLeft();
  }, []);

  const claimTokens = async () => {
    try {
      if (!contract) {
        console.error("Contract instance is not initialized");
        return;
      }
      if (remainingTokens < CLAIMABLE_AMOUNT) {
        console.error("Not enough tokens left to claim.");
        setAllTokensClaimed(true);
        return;
      }

      // Call the serverless function to get the Merkle proof
      const response = await fetch(
        `https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-5f3627f0-62d9-46c0-9dd0-c8e2ae2c8194/cloud/getMerkleProof?address=${selectedAccount}`
      );
      const result = await response.json();

      if (response.ok && result.proof) {
        // Convert the proof string to an array
        const proofArray = result.proof.split(",").map((proof) => proof.trim());
        showNotification(
          "Transaction initiated, please wait for confirmation.",
          true
        ); // Show notification with infinite duration

        if (!referrer || !isValidEthereumAddress(referrer)) {
          const tx = await contract.claimTokens(
            CLAIMABLE_AMOUNT_NOWEI,
            proofArray
          );
          await provider.waitForTransaction(tx.hash);
          showNotification("Tokens claimed successfully!", false, true);
        } else {
          const tx = await contract.claimTokensWithReferral(
            CLAIMABLE_AMOUNT_NOWEI,
            proofArray,
            referrer
          );
          await provider.waitForTransaction(tx.hash);
          showNotification("Tokens claimed successfully!", false, true);
        }
        console.log("Tokens claimed successfully");
        setShowClaimVested(true);
      } else {
        console.log("You are not eligible to claim");
        setIsEligible(false); // Set eligibility to false if proof not found
      }
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        showNotification("Transaction rejected by user.");
      } else {
        showNotification("Error claiming tokens. Please try again.");
      }
      console.error("Error claiming tokens:", error);
    }
  };

  const claimVestedTokens = async () => {
    if (contract && account) {
      showNotification("Transaction In Progress", true); // Show notification with infinite duration
      const gasLimit = 300000;
      try {
        const tx = await contract.claimVestedTokens({ gasLimit });
        await provider.waitForTransaction(tx.hash); // Wait for the transaction to be mined
        showNotification("Vested tokens claimed successfully!", false, true); // Show success notification and update data
        console.log("Vested tokens claimed successfully");
        setShowClaimVested(true);
        updateAllData();
      } catch (error) {
        if (error.code === "ACTION_REJECTED") {
          showNotification("Transaction rejected by user.");
        } else {
          showNotification("Error claiming vested tokens. Please try again.");
        }
        console.error("Error claiming vested tokens:", error);
      }
    }
  };

  const updateAllData = async () => {
    if (provider && account) {
      const signer = provider.getSigner();
      console.log("Hey You Get Out of Here...");
      await fetchMemeCoinBalance(signer, account);
      await fetchLastClaimTimestamp(contract, account);
      await fetchVestedAmount(contract, account);
      await fetchRemainingTokens(contract);
      await checkIfClaimed(contract, account); // Check if tokens have been claimed
    }
  };

  const showNotification = (
    message,
    infiniteDuration = false,
    updateData = false
  ) => {
    setNotification({ message, progress: 0 });

    if (!infiniteDuration) {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setNotification((prev) => ({ ...prev, progress }));
        if (progress >= 100) {
          clearInterval(interval);
          setNotification(null);
          if (updateData) {
            updateAllData(); // Trigger data update after notification
          }
        }
      }, 300);
    }
  };

  const abbreviateAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  useEffect(() => {
    const chainItems = document.querySelectorAll(".chain-item");
    chainItems.forEach((item) => adjustFontSize(item));

    const handleResize = () => {
      chainItems.forEach((item) => adjustFontSize(item));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="airdrop-page">
      <Navbar />
      <div className="content2">
        <div className="wallet-info-container">
          {account && (
            <div className="wallet-info">
              <p>Connected Wallet:</p>
              <p>{abbreviateAddress(account)}</p>
              <div className="wallet-balance">
                <span>
                  Wallet Balance:{" "}
                  {balance !== null ? (
                    <TypedText phrases={[`${balance} OOPS`]} fontSize={10} />
                  ) : (
                    "Loading..."
                  )}
                </span>{" "}
              </div>
            </div>
          )}
          <div className="wallet-button-container">
            <RetroButton onClick={account ? disconnectWallet : connectWallet}>
              {account ? "Disconnect Wallet" : "Connect Wallet"}
            </RetroButton>
          </div>
        </div>
        <div className="contract-info-container">
          <div className="contract-info">
            <p>Airdrop Contract Address:</p>
            <p>{airdropContract}</p>
          </div>
        </div>
        <h1 className="title">Orbital Octopus Airdrop</h1>
        <div className="phrases">
          <p>Dive into the Orbital Octopus Nebula!</p>
          <p>Connect your MetaMask wallet to seize celestial bounties!</p>
          <p>Journey through the Orbital Octopus universe!</p>
        </div>
        <div className="claim-section">
          <div className="airdrop-details">
            <h2>Airdrop Details</h2>
            <ul>
            <li>
                Total Airdrop Supply:{" "}
                <TypedText phrases={[`${Number(400000000000000)} OOPS`]} fontSize={dynamicFontSize} />
              </li>
              <li>
                Claimable Amount:{" "}
                <TypedText phrases={[`${Number(CLAIMABLE_AMOUNT) / 10 ** 9} OOPS`]} fontSize={dynamicFontSize} />
              </li>
              <li>
                Tokens Remaining:
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{ width: `${percentageLeft}%` }}
                  ></div>
                </div>
              </li>
            </ul>
          </div>
          {wrongChain && (
            <div className="wrong-chain-message">
              <p className="neon-red">
                Please connect to the Base chain to participate in the airdrop.
              </p>
            </div>
          )}
          {!wrongChain && account && !hasClaimed && !allTokensClaimed && (
            <>
              <div className="claim-button">
                <RetroButton onClick={claimTokens}>Claim Now</RetroButton>
              </div>
            </>
          )}
          {!wrongChain && account && showClaimVested && vestedAmount > 0 && (
            <>
              <div className="claim-vested-button">
                <RetroButton onClick={claimVestedTokens}>
                  Claim Unlocked Tokens ({amountFreeToClaim} available)
                </RetroButton>
              </div>
              <div className="unvested-amount">
                <p>Locked Total: {Number(vestedAmount) / 10 ** 9} OOPS</p>
              </div>
            </>
          )}
          {allTokensClaimed && (
            <div>
              <p>All tokens have been claimed.</p>
              <p>No more tokens available.</p>
              <p>Thank you for participating!</p>
            </div>
          )}
          {!isEligible && (
            <div className="not-eligible-message">
              <p>You are not eligible to claim tokens.</p>
              <p>Please check the eligibility requirements.</p>
            </div>
          )}
        </div>
        <div className="eligibility-section">
          <h2>Eligibility Requirements</h2>
          <p className="no-glow">
            To be eligible for the Orbital Octopus airdrop, you simply need to
            have interacted with the{" "}
            <span className="red-text">Base network</span> in any way that used
            gas. This includes transactions, contract interactions, or any
            activity that required gas on the{" "}
            <span className="red-text">Base network</span>. There are no
            specific token requirements—
            <span className="red-text">
              any valid interaction with the Base chain qualifies you for the
              airdrop
            </span>
            . <br />
            <br />
            <span className="highlight-date">
              You must have interacted with the Base network before{" "}
              <strong className="red-text">September 2, 2024 Laber Day</strong> to be
              eligible.
            </span>
          </p>
          <h3>Token Distribution Details</h3>
          <p className="no-glow">
            <strong className="red-text">Initial 10% Upfront:</strong> When you
            claim your airdrop, you will receive 10% of your allocated tokens
            immediately. This is our way of saying thank you for your early
            support.
          </p>
          <p className="no-glow">
            <strong>
              <span className="red-text">
                Remaining 90% Unlock Over 6 Months:
              </span>
            </strong>{" "}
            The remaining 90% of your airdrop tokens will be locked and will
            gradually unlock over the course of six months. This ensures a
            stable distribution and helps prevent market fluctuations.
          </p>
          <div className="learn-more-button2">
            <a
              href="https://8bit-nft.gitbook.io/orbital-octopus/airdrop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RetroButton>Learn More</RetroButton>
            </a>
          </div>
        </div>
        <div className="referral-section">
          <h2>Referral Program</h2>
          <p className="no-glow">
            Earn more by referring others to the Orbital Octopus Airdrop. Click
            the button below to learn more about our referral program
          </p>
          <div className="referral-button">
            <Link to="/referral">
              <RetroButton>Learn More</RetroButton>
            </Link>
          </div>
        </div>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          progress={notification.progress}
        />
      )}
    </div>
  );
};

export default AirdropPage;
