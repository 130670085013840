import React, { useState } from 'react';
import './KickbackCalculator.css';

const KickbackCalculator = () => {
  const [userQty, setUserQty] = useState('');
  const [dailyVolume, setDailyVolume] = useState('');
  const [marketCap, setMarketCap] = useState('');
  const circulatingSupply = '200000000000000'; // Default value
  const kickbackPercentage = 0.000025; // Fixed percentage

  const calculateReflections = () => {
    const yourQtyFloat = parseFloat(userQty.replace(/\,/g, ''));
    const dailyVolumeFloat = dailyVolume ? parseFloat(dailyVolume.replace(/\,/g, '')) : 0;
    const marketCapFloat = parseFloat(marketCap.replace(/\,/g, ''));
    const circulatingSupplyFloat = parseFloat(circulatingSupply.replace(/\,/g, ''));

    const price = marketCapFloat / circulatingSupplyFloat;
    const dailyReflections = (dailyVolumeFloat * kickbackPercentage) * (yourQtyFloat / circulatingSupplyFloat);
    const monthlyReflections = dailyReflections * 30;
    const annualReflections = dailyReflections * 365;
    const totalValue = yourQtyFloat * price;

    return {
      dailyReflections: dailyReflections.toFixed(2),
      monthlyReflections: monthlyReflections.toFixed(2),
      annualReflections: annualReflections.toFixed(2),
      price: price.toFixed(8),
      totalValue: totalValue.toFixed(2),
    };
  };

  const reflections = calculateReflections();

  return (
    <div className="kickback-calculator-container">
      <h1>Kickback Calculator</h1>
      <div className="kickback-calculator-grid">
        <div className="kickback-calculator-inputs">
          <label>Qty Owned</label>
          <input
            type="text"
            value={userQty}
            onChange={(e) => setUserQty(e.target.value)}
          />
          <label>Daily Volume (USD) <span className="optional">(Optional)</span></label>
          <input
            type="text"
            value={dailyVolume}
            onChange={(e) => setDailyVolume(e.target.value)}
          />
          <label>Market Cap (USD)</label>
          <input
            type="text"
            value={marketCap}
            onChange={(e) => setMarketCap(e.target.value)}
          />
          <label>Circulating Supply</label>
          <input
            type="text"
            value={circulatingSupply}
            disabled
          />
        </div>
        <div className="kickback-calculator-results">
          <h2>Reflections Summary</h2>
          <p>Daily Reflections: ${reflections.dailyReflections}</p>
          <p>Monthly Reflections: ${reflections.monthlyReflections}</p>
          <p>Annual Reflections: ${reflections.annualReflections}</p>
          {/* <p>Price: ${reflections.price}</p> */}
          <p>Total Value: ${reflections.totalValue}</p>
        </div>
      </div>
    </div>
  );
};

export default KickbackCalculator;
