import React, { useState, useEffect } from 'react';
import './Notification.css';

const Notification = ({ message, progress }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (progress >= 100) {
            setTimeout(() => setVisible(false), 5000); // Set timeout to 5 seconds
        }
    }, [progress]);

    if (!visible) return null;

    return (
        <div className="notification">
            <div className="notification-message">{message}</div>
            <div className="notification-progress-bar">
                <div className="notification-progress" style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
};

export default Notification;
