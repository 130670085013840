import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/upsetSquid1.png'; // Ensure the path to your logo image is correct

const Navbar = ({ toggleVisibility }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.location.reload(); // Refresh the page if already on the home route
    } else {
      navigate('/');
      window.location.reload(); // Navigate to home and refresh the page
    }
  };

  // Updated handleBuyClick to redirect to an external URL
  const handleBuyClick = () => {
    window.location.href = 'https://app.alienbase.xyz/swap?outputCurrency=0x6bB56dA93DAd8312eE1FcEBd07D55D61b33B9A7c&inputCurrency=ETH'; // Replace with your actual external URL
  };

  const handleGameClick = () => {
    navigate('/#game');
    setIsOpen(false); // Close the menu after navigating
  };

  const handleToggleBackgroundClick = () => {
    toggleVisibility();
    setIsOpen(false); // Close the menu after toggling the background
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location.pathname]);

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <button className="navbar-logo" onClick={handleLogoClick}>
          <img src={logo} alt="Logo" className="navbar-logo-image" />
          Orbital Octopus
        </button>
      </div>
      <div className={`navbar-right ${isOpen ? 'open' : ''}`}>
        {location.pathname !== '/' && (
          <Link to="/">
            <button className="navbar-button">Home</button>
          </Link>
        )}
        {location.pathname !== '/airdrop' && (
          <Link to="/airdrop">
            <button className="navbar-button">Airdrop</button>
          </Link>
        )}
        {location.pathname !== '/referral' && (
          <Link to="/referral">
            <button className="navbar-button">Referrals</button>
          </Link>
        )}
        {location.pathname !== '/kickback-calculator' && (
          <Link to="/kickback-calculator">
            <button className="navbar-button">Kickback Calculator</button>
          </Link>
        )}
        {location.pathname !== '/buy' && (
          <button className="navbar-button" onClick={handleBuyClick}>Buy</button>
        )}

        {location.pathname === '/' && (
          <>
            <button className="navbar-button" onClick={handleGameClick}>Game</button>
            <button className="navbar-button" onClick={handleToggleBackgroundClick}>Toggle Background</button>
          </>
        )}
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <span className="navbar-toggle-icon">&#9776;</span>
      </div>
    </nav>
  );
};

export default Navbar;
