import React from 'react';
import './Footer.css';
import instagramLogo from '../assets/instagram_logo.png';
import tiktokLogo from '../assets/tiktok_logo.png';
import githubLogo from '../assets/github_logo.png';
import telegramLogo from '../assets/telegram_logo.png';
import discordLogo from '../assets/discord_logo.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <p className="footer-text">© 2024 Orbital Octopus. All rights reserved.</p>
          <div className="social-media">
            {/* <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram Logo" />
            </a> */}
            {/* <a href="https://www.tiktok.com/" target="_blank" rel="noopener noreferrer">
              <img src={tiktokLogo} alt="TikTok Logo" />
            </a> */}
            <a href="https://github.com/clodge123/Orbital-Octopus-Contracts" target="_blank" rel="noopener noreferrer">
              <img src={githubLogo} alt="GitHub Logo" />
            </a>
            <a href="https://t.me/OrbitalOctopus" target="_blank" rel="noopener noreferrer">
              <img src={telegramLogo} alt="Telegram Logo" />
            </a>
            <a href="https://discord.gg/EEtsYer7" target="_blank" rel="noopener noreferrer">
              <img src={discordLogo} alt="Discord Logo" />
            </a>
            <a href="https://8bit-nft.gitbook.io/orbital-octopus" target="_blank" rel="noopener noreferrer">
              <p className="gitbook-text">GitBook</p>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
