import React, { useEffect, useState } from 'react';
import './NewsSection.css';

const NewsSection = () => {
  const [typedText, setTypedText] = useState([]);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [currentCharIndexes, setCurrentCharIndexes] = useState([]);
  const [isTyping, setIsTyping] = useState(true);
  const [isLinkTyping, setIsLinkTyping] = useState(false);
  const [resumeTypingTimeout, setResumeTypingTimeout] = useState(null);

  const phrases = [
    { 
      text: "Claim your airdrop tokens today\nOver 1.6 million address are eligible\nDont miss this once in a lifetime airdrop", 
      pause: 10000,
      link: { text: "Learn more", url: "/airdrop" }
    },
    { 
      text: "Passive Income with Kickback System\nCalculate rewards with Kickback Calculator", 
      pause: 6000,
      link: { text: "Kickback Calculator", url: "/kickback-calculator" }
    },
    { 
      text: "Play the game today\nToggle background to play full screen", 
      pause: 6000,
      link: null
    },
    { 
      text: "Wasn't eligible for the airdrop?\n Refer others today and receive 1% for each referral!!!", 
      pause: 6000,
      link: { text: "Learn More", url: "/referral" }
    }
  ];

  const typingSpeed = 150; // consistent typing speed
  const shortPauseDuration = 250; // ms

  useEffect(() => {
    const typeText = () => {
      const { text, pause, link } = phrases[currentPhraseIndex];
      const lines = text.split('\n');

      if (typedText.length === 0) {
        setTypedText(new Array(lines.length).fill(""));
        setCurrentCharIndexes(new Array(lines.length).fill(0));
        return;
      }

      let allLinesCompleted = true;
      const updatedText = typedText.map((line, index) => {
        if (currentCharIndexes[index] < (lines[index]?.length || 0)) {
          allLinesCompleted = false;
          return line + lines[index][currentCharIndexes[index]];
        }
        return line;
      });

      if (!allLinesCompleted) {
        setTypedText(updatedText);
        setCurrentCharIndexes(currentCharIndexes.map((charIndex, index) => 
          charIndex < (lines[index]?.length || 0) ? charIndex + 1 : charIndex
        ));
      } else if (!isLinkTyping && link) {
        setIsLinkTyping(true);
        setTypedText([...updatedText, ""]);
        setCurrentCharIndexes([...currentCharIndexes, 0]);
      } else if (isLinkTyping && currentCharIndexes[lines.length] < link.text.length) {
        setTypedText((prevText) => {
          const newText = [...prevText];
          newText[lines.length] += link.text[currentCharIndexes[lines.length]];
          return newText;
        });
        setCurrentCharIndexes((prevIndexes) => {
          const newIndexes = [...prevIndexes];
          newIndexes[lines.length]++;
          return newIndexes;
        });
      } else {
        setTimeout(() => {
          setIsTyping(false);
          setIsLinkTyping(false);
          setTimeout(() => {
            setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
            setTypedText([]);
            setCurrentCharIndexes([]);
            setIsTyping(true);
          }, pause);
        }, shortPauseDuration);
      }
    };

    let intervalId;

    if (isTyping || isLinkTyping) {
      intervalId = setTimeout(typeText, typingSpeed);
    }

    return () => clearTimeout(intervalId);
  }, [currentPhraseIndex, currentCharIndexes, typedText, isTyping, isLinkTyping]);

  const handleDotClick = (index) => {
    setCurrentPhraseIndex(index);
    resetTyping(index);
  };

  const handleArrowClick = (direction) => {
    let newIndex;
    if (direction === 'left') {
      newIndex = (currentPhraseIndex - 1 + phrases.length) % phrases.length;
    } else if (direction === 'right') {
      newIndex = (currentPhraseIndex + 1) % phrases.length;
    }
    setCurrentPhraseIndex(newIndex);
    resetTyping(newIndex);
  };

  const resetTyping = (index) => {
    const { text, link } = phrases[index];
    const lines = text.split('\n');
    setTypedText([...lines, link ? link.text : ""]);
    setCurrentCharIndexes([...lines.map(line => line.length), link ? link.text.length : 0]);
    setIsTyping(false);
    setIsLinkTyping(false);

    if (resumeTypingTimeout) {
      clearTimeout(resumeTypingTimeout);
    }

    const delay = phrases[index].pause;
    setResumeTypingTimeout(setTimeout(() => setIsTyping(true), delay));
  };

  return (
    <div className="news-section">
      <div className="arrow left-arrow" onClick={() => handleArrowClick('left')}>&#8249;</div>
      <div className="typed-text-container">
        {typedText.map((line, index) => (
          <div key={index} className="typed-text-line">
            {index === typedText.length - 1 && phrases[currentPhraseIndex].link && !isTyping ? (
              <a href={phrases[currentPhraseIndex].link.url} className="typed-text-link">
                {line}
              </a>
            ) : (
              line
            )}
          </div>
        ))}
      </div>
      <div className="arrow right-arrow" onClick={() => handleArrowClick('right')}>&#8250;</div>
      <div className="carousel-controls">
        <div className="carousel-counter">
          {currentPhraseIndex + 1}/{phrases.length}
        </div>
        <div className="carousel-dots">
          {phrases.map((_, index) => (
            <div
              key={index}
              className={`carousel-dot ${index === currentPhraseIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
